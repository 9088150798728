import { action, observable } from 'mobx';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import Copy from '@helpers/copy';
import { ROLE_ADMIN, ROLE_SUPERUSER } from '../@helpers/RoleTypes';
import DataHandlerStore from './DataHandlerStore';
import NotificationStore from './NotificationStore';
import BaseStore from './BaseStore';
import { authEndpoint } from '@helpers/config';
import { sendInvalidation } from '../api/invalidate';
import { SupportUtils } from '../@helpers/supportUtils';

export interface ResetPasswordValidationDto {
    link?: string;
    password?: string;
    account?: string;
}

export interface ResetPasswordResponseDto {
    updated?: boolean;
    token?: string;
    validationPassed: boolean;
    validationErrors: ResetPasswordValidationDto;
}
export interface ResetPasswordModel {
    link?: any;
    previousPassword?: string;
    password: string;
}

const { fetch } = DataHandlerStore;

export interface LoginModel {
    email?: any;
    password?: any;
}

interface LoginProcessFlowDto {
    success: boolean;
    token?: string;
    error?: {
        rejectReason: 'some reason'
    };
    originalResponse: any;
}

export class LoginStoreBlueprint extends BaseStore {
    @observable auth = {};
    @observable current: any = {};
    @observable showResetPasswordForm = false;
    @observable notificationCopy: any = null;
    @observable notificationTheme: any;
    @observable loading: Array<any> = [];
    @observable source: any = axios.CancelToken.source();
    @observable formLabel: any = Copy.messages.login.before;

    private loginResponseHandler = async (data: any): Promise<LoginProcessFlowDto> => {
        return {
            success: data.validationPassed,
            originalResponse: data,
            token: data.validationPassed ? data.token : ''
        };
    };

    @action
    login = async (user: LoginModel, from: any) => {
        try {
            const flowDto = await this.loginResponseHandler(await authenticate(user));

            if (flowDto.success) {
                this.auth = flowDto.originalResponse;
                this.current = jwtDecode(flowDto.token as string);
                await this.getCountries();
                window.location.pathname = from.from.pathname;
                return Promise.resolve(flowDto.originalResponse);
            }

            let keysToRemove = ['countries', 'current-user', 'contactOptions', 'menu', 'token', 'userLanguage', 'extracts'];
            keysToRemove.forEach(k =>
              localStorage.removeItem(k))
            return Promise.reject(flowDto.originalResponse.validationErrors);
        } catch (error) {
            this.showError(error);
            return Promise.reject(error);
        }
    };

    @action
    forgotPassword = async (user: LoginModel) => {
        try {
            const auth = await forgotenPassword(user);
            return Promise.resolve(auth);
        } catch (error) {
            this.showError(error);
            return Promise.reject(error);
        }
    };

    @action
    createNewUser = async (email: string) => {
        try {
            const auth = await createNewUser(email);
            return Promise.resolve(auth);
        } catch (error) {
            this.showError(error);
            return Promise.reject(error);
        }
    };

    @action
    handleShowForm = () => {
        this.showResetPasswordForm = !this.showResetPasswordForm;
        NotificationStore.updateShowNotification(false);
    };

    @action
    handleSubmissionFailure = (errorMessage) => {
        this.notificationCopy = errorMessage;
        NotificationStore.updateShowNotification(true);
        this.notificationTheme = 'failure';
        DataHandlerStore.updateShowError(false);
    };

    @action('is called when submission is successful')
    handleSubmissionSuccess = (message) => {
        NotificationStore.updateShowNotification(true);
        this.notificationCopy = message;
        this.notificationTheme = 'success';
    };

    @action
    logOut = async () => {
        await sendInvalidation();
        let keysToRemove = ['countries', 'current-user', 'contactOptions', 'menu', 'token', 'userLanguage', 'extracts'];
            keysToRemove.forEach(k =>
              localStorage.removeItem(k))
        window.location.href = '/login';
    };

    @action
    changePassword = async (params: ResetPasswordModel) => {
        let keysToRemove = ['countries', 'current-user', 'contactOptions', 'menu', 'token', 'userLanguage', 'extracts'];
        keysToRemove.forEach(k => localStorage.removeItem(k))

        try {
            const changeResponseX: any = await DataHandlerStore.post(authEndpoint.resetPassword.name, params);

            if (changeResponseX.response) {
                return changeResponseX.response as ResetPasswordResponseDto;
            } else {
                if (changeResponseX.updated) {
                    window.location.href = '/';
                    return undefined;
                }

                if (changeResponseX.validated !== undefined && changeResponseX.validated === false &&
                    changeResponseX.rejectReason !== undefined && changeResponseX.rejectReason === 'account-disabled') {

                    const emailForSupport = SupportUtils.getSupportEmail();

                    return {
                        validationErrors: {
                            account: `Your password was changed however your account is not currently active, contact ${emailForSupport}`
                        }
                    } as ResetPasswordResponseDto;
                }
            }
        } catch (error) {
            this.showError(error);
            return error;
        }
    }

    showError = (error) => {
        if (!error.response) {
            this.handleSubmissionFailure(error.toString());
        } else {
            const { data } = error.response;
            if (data && data.validationErrors) {
                Object.keys(data.validationErrors).forEach(key => {
                    const message = data.validationErrors[key];
                    this.notificationCopy = message;
                    this.handleSubmissionFailure(message)
                })
                return data;
            }
        }
    }
}

const authenticate = async (user: LoginModel) => {
    const request = {
        action: authEndpoint.authenticate.name,
        body: {
            user: user.email,
            password: user.password,
        },
    };

    const response: any = await fetch(request);
    const data = response.data;

    let authenticationSuccessful: boolean = false;
    let allowLogin: boolean = false;
    let requiresMfaSettingUp: boolean = false;
    let requiresMfaChallenge: boolean = false;

    if (data.authenticationAttempt === undefined) {
        // old api
        if (data.token) {
            authenticationSuccessful = true;
            allowLogin = true;
        }
    } else {
        // new api
        if (data.authenticationAttempt && data.authenticationAttempt.success && data.authenticationAttempt.isVerifiedUser && data.token) {
            authenticationSuccessful = true;

            if (data.mfaSetupRequired !== undefined && data.mfaSetupRequired) {
                requiresMfaSettingUp = true;
            } else if (data.mfaChallengeRequired !== undefined && data.mfaChallengeRequired) {
                requiresMfaChallenge = true;
            } else if (data.token) {
                allowLogin = true;
            }
        }
    }

    if (requiresMfaSettingUp) {
        // redirect to setup page
        throw new Error('Sorry, you need to setup MFA before you can login');
    }

    if (requiresMfaChallenge) {
        // redirect to challenge page
        throw new Error('Sorry, you need to complete MFA challenge before you can login');
    }

    if (!authenticationSuccessful || !allowLogin) {
        throw new Error('Sorry, there was an issue logging you in');
    }

    const token = data.token;
    const role = getJwtUserRole(token);

    const isRoleValid = [
        ROLE_SUPERUSER,
        ROLE_ADMIN,
    ].includes(role);

    if (!isRoleValid) {
        throw new Error('Sorry, those details aren’t valid. Please log in with your administrative credentials to access the website');
    }

    localStorage.setItem('token', token);
    localStorage.setItem('userLanguage', 'en-GB');
    return Promise.resolve(data);
};

export const getJwtUserRole = (token: string): string => {
    const claims: any = jwtDecode<any>(token);

    if (claims.publicClaims !== undefined && claims.publicClaims.role !== undefined) {
        return claims.publicClaims.role;
    } else if (claims.role !== undefined) {
        return claims.role;
    }

    throw new Error('Role not found in JWT claims');
};

const forgotenPassword = async (user: LoginModel) => {
    try {
        const data = {
            action: authEndpoint.forgotPassword.name,
            body: {
                email: user.email,
            },
        };
        const authenticated: any = await fetch(data);
        return Promise.resolve(authenticated);
    } catch (error) {
        return Promise.reject(error);
    }
};

const createNewUser = async (email: string) => {
    try {
        const data = {
            action: authEndpoint.createNewUser.name,
            body: {
                email,
            },
        };
        const authenticated: any = await fetch(data);
        return Promise.resolve(authenticated);
    } catch (error) {
        return Promise.reject(error);
    }
};

const AuthStore = new LoginStoreBlueprint();
export default AuthStore;
